import React from "react"
import loadable from "@loadable/component"

const PortableText = loadable(() => import("../PortableText"))

const HeadingContent = ({ title, subtitle, hideSubtitle, text }) => (
  <>
    {subtitle && (
      <p
        className={`text-16 md:text-22 font-roboto font-bold text-white tracking-subtitle ${
          hideSubtitle ? "hidden md:block" : ""
        }`}
      >
        {subtitle}
      </p>
    )}
    {title && (
      <h1 className="mt-4 tracking-widest leading-none text-white uppercase text-32 md:text-65 md:tracking-header-xl font-roboto md:transform md:-translate-x-1 md:-translate-y-4">
        {title}
      </h1>
    )}
    {text && (
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-6 home-text">
          <PortableText blocks={text} isLight isHeaderHome />
        </div>
      </div>
    )}
  </>
)

export default HeadingContent
